@tailwind base;
@tailwind components;
@tailwind utilities;


.main-section{
    height: max-content;
    margin-top: 10%;
}

.btn{
    margin-top: 2rem;
}
.wrapper{
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .main-section{
        margin-top: 30%;
    }
    
}